<template>
  <v-app>
    <nav-bar />
    <v-main>
      <router-view />
      <footer-main />
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import NavBar from '@/components/NavBar.vue';
import FooterMain from '@/components/Footer.vue';

export default {
  components: { NavBar, FooterMain },
  name: 'App',
  created() {
    // this.closeAllSession();
    this._getCurrency();
    this.getStockLimit();
  },

  // metaInfo: {
  //   titleTemplate: "%s | Cicsis",
  //   meta: [
  //     {
  //       vmid: "description",
  //       name: "description",
  //       content:
  //         "Cicsis es una web conectada que brinda el servicio de conexion a syscom",
  //     },
  //   ],
  // },

  computed: {},

  methods: {
    ...mapActions('Currency', ['getCurrency']),
    ...mapActions('auth', ['closeSession']),

    closeAllSession() {
      this.$http.interceptors.response.use(
        (res) => {
          return res;
        },
        (error) => {
          if (error.response.status == 401) {
            this.$swal({
              icon: 'error',
              title: error.response.status,
              text: error.response.data.message,
              showCancelButton: true,
              confirmButtonText: 'Close sesion',
              cancelButtonText: `Cancel`
            }).then((result) => {
              if (this.$cookies.get('token')) {
                this.$cookies.remove('token');
              }

              this.closeSession();
            });
          }
          return Promise.reject(error);
        }
      );
    },

    async _getCurrency() {
      await this.getCurrency();
    },

    async getStockLimit() {
      await this.$http
        .get('/stock')
        .then((result) => {
          const data = result.data;
          this.$store.commit('setStockLimit', data ? data : 30);
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('setStockLimit', 30);
        });
    }
  }
};
</script>
<style>
#app {
  background-color: #f6f6f6;
}
.product_total {
  /* color: #23b9d7; */
  font-size: 1.3rem;
  font-weight: bold;
}
</style>
