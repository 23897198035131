export function setUsers(state, users) {
  state.users = users;
  state.user = null;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setOneUser(state, oneUser) {
  state.oneUser = oneUser;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setAddress(state, data) {
  state.address = data;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function UserError(state, error) {
  state.error = true;
  state.errorMessage = error;
  state.success = false;
  state.successMessage = "";
}

export function setSuccess(state, payload) {
  state.success = true;
  state.successMessage = payload;
  state.error = false;
  state.errorMessage = "";
}

export function setUserLoading(state, payload) {
  state.UserLoading = payload;
}

export function setUsersChart(state, payload) {
  state.usersChart = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setUserFinded(state, payload) {
  state.userFindedSuccess = true;
  state.userFindedMsg = payload;
  state.userFindedError = false;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setUserFindedError(state, payload) {
  state.userFindedError = true;
  state.userFindedMsg = payload;
  state.userFindedSuccess = false;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setClearErrorCodes(state, payload) {
  state.userFindedError = false;
  state.userFindedMsg = "";
  state.userFindedSuccess = false;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}
