import axios from "axios";
import * as cookies from "vue-cookies";

export async function registerUser({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });
    await axios
      .post("/auth/signup", data)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.log(e);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function loginUser({ commit }, data) {
  commit("setUser", data.user);
  commit("setRole", data.role);
  window.location.reload();
  // try {
  //   commit("setLoading", true, { root: true });
  //   await axios
  //     .post("/auth/login", data)
  //     .then((response) => {
  //       const { accessToken } = response.data;
  //       console.log({ response });
  //       commit("setUser", response.data);
  //       commit("setRole", response.data.role);
  //       localStorage.setItem("userId", response.data._id);
  //       localStorage.setItem("token", accessToken);
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       console.log({ error });
  //       commit("authError", error.response.data.message);
  //     });
  // } catch (e) {
  //   console.log(e);
  //   commit("authError", e);
  // } finally {
  //   commit("setLoading", false, { root: true });
  // }
}

export async function forgotPassword({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });
    await axios
      .post("/auth/forgot-password", data)
      .then((response) => {
        console.log(response);
        commit(
          "setResetPassword",
          "Se envió un correo para restablecer su contraseña"
        );
      })
      .catch((err) => {
        commit("setErrorPassword", err.response.data.message);
      });
  } catch (e) {
    console.log(e);
    commit("setErrorPassword", "Ocurrió un error");
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function resetPassword({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });
    await axios
      .post(`/auth/reset-password/${data.userId}/${data.token}`, data)
      .then((response) => {
        commit("setResetPassword", response.data.message);
      })
      .catch((err) => {
        commit("setErrorPassword", err.response.statusText);
        console.log(err.response.statusText);
      });
  } catch (e) {
    console.log(e.response.statusText);
    commit("setErrorPassword", e.response.statusText);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export function closeSession({ commit }) {
  commit("logout", null);
  localStorage.removeItem("userId");
  localStorage.removeItem("token");
  window.location.reload();
}

export async function getMyInfo({ commit }, data) {
  try {
    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };
    await axios
      .get(`/profile`, { headers })
      .then((result) => {
        console.log(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
}
