export default {
  categories: null,
  category: null,
  error: false,
  errorMessage: "",
  success: false,
  successMessage: "",
  categoryLoading: false,
  publishCategoryError: false,
  publishCategoryMsg: "",
};
