import axios from "axios";

export function saveProductInCart({ commit, state }, product) {
  let searched = state.cart.find((search) => search._id == product._id);
  console.log(searched);

  if (searched) {
    searched.quantity = product.quantity;
    searched.totalPayment = searched.quantity * searched.l1;

    commit("setCart", state.cart);

    let count = 0;
    state.cart.map((item) => {
      return (count += item.quantity);
    });
    commit("setCount", count);
  } else {
    product.totalPayment = product.quantity * product.l1;
    state.cart.push(product);

    let count = 0;
    state.cart.map((item) => {
      return (count += item.quantity);
    });
    commit("setCount", count);
  }
}

export function saveInCart({ commit }, data) {
  commit("setCart", data);
  // let searched = state.cart.find((search) => search._id == product._id);
  // if (searched) {
  //   searched.quantity += searched.quantity + product.quantity;
  //   searched.totalPayment += searched.quantity * searched.precios.precio_1;
  //   commit("setCart", state.cart);
  // } else {
  //   product.totalPayment = product.quantity * product.l1;
  //   state.cart.push(product);
  // }
}

export function removeProductCart({ commit, state }, product) {
  let searched = state.cart.find((item) => item._id == product._id);
  if (searched) {
    searched.quantity = product.quantity;
    searched.totalPayment = searched.quantity * searched.l1;

    if (state.cart.length > 0) {
      let count = 0;
      state.cart.map((item) => {
        return (count += item.quantity);
      });
      commit("setCount", count);
    }

    if (searched.quantity == 0) {
      let saved = state.cart.filter((item) => item._id != product._id);
      return commit("setCart", saved);
    }

    commit("setCart", state.cart);
  }
}

export function saveCart({ commit }, data) {
  commit("setCart", data);
}

export async function paymentCart({ commit }, data) {
  try {
    await axios
      .post(`/orders/`, data)
      .then((response) => {
        console.log(response.data);
        commit("setSuccessCart", "Se a creado la orden con exito");
      })
      .catch((e) => {
        commit("setErrorCart", e.response.data.message);
        console.log(e.response.data.message);
      });
  } catch (e) {
    commit("setErrorCart", e.response.data.message);
    console.log(e.response.data.message);
  }
}
