export default {
  bills: [],
  clients: [],
  payments: [],
  uses: [],
  bill: null,
  error: false,
  errorMessage: "",
  success: false,
  successMessage: "",
};
