export function setCurrency(state, payload) {
  state.currency = payload;
}

export function setType(state, payload) {
  state.typeChange = payload;
}

export function set_ID(state, payload) {
  state._id = payload;
}
