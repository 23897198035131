export function setQuotes(state, payload) {
  state.quotes = payload;
  state.quote = null;
  state.error = false;
  state.errorMessage = "";
}

export function setQuote(state, payload) {
  state.quotes = null;
  state.quote = payload;
  state.error = false;
  state.errorMessage = "";
}

export function setSuccess(state, payload) {
  state.error = false;
  state.errorMessage = "";
  state.successMessage = payload;
  state.success = true;
}

export function setError(state, payload) {
  state.error = true;
  state.errorMessage = payload;
  state.success = false;
  state.successMessage = "";
}
