export function setStores(state, payload) {
  state.stores = payload;
  state.store = null;
  state.error = false;
  state.errorMessage = "";
}

export function setStore(state, payload) {
  state.stores = null;
  state.store = payload;
  state.error = false;
  state.errorMessage = "";
}

export function setSuccess(state, payload) {
  state.error = false;
  state.errorMessage = "";
  state.success = true;
  state.successMessage = payload;
}

export function setError(state, payload) {
  state.error = true;
  state.errorMessage = payload;
  state.success = false;
  state.successMessage = "";
}
