export function totalQuantity(state) {
  return Object.values(state.cart).reduce(
    (acc, { quantity }) => acc + quantity,
    0
  );
}

export function totalPrice(state) {
  return Object.values(state.cart).reduce((acc, { total }) => acc + total, 0);
}
