export function setOrders(state, orders) {
  state.orders = orders;
  state.order = null;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setOrder(state, order) {
  state.order = order;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function orderError(state, error) {
  state.error = true;
  state.errorMessage = error;
  state.success = false;
  state.successMessage = "";
}

export function setSuccess(state, payload) {
  state.success = true;
  state.successMessage = payload;
  state.error = false;
  state.errorMessage = "";
}

export function setOrderLoading(state, payload) {
  state.orderLoading = payload;
}

export function setPagination(state, payload) {
  state.pagination = payload;
}
