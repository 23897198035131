export function setCostumers(state, payload) {
  state.costumers = payload;
  state.costumer = null;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setPagination(state, payload) {
  state.pagination = payload;
}

export function setCostumer(state, payload) {
  state.costumer = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}
export function setError(state, payload) {
  state.error = true;
  state.errorMessage = payload;
  state.success = false;
  state.successMessage = "";
}
export function setSuccess(state, payload) {
  state.success = true;
  state.successMessage = payload;
  state.error = false;
  state.errorMessage = "";
}

export function setClearCodes(state, payload) {
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}
