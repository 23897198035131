export function setCart(state, payload) {
  state.cart = payload;
}

export function setCount(state, payload) {
  state.count = payload;
}

export function setCartView(state, payload) {
  state.cart[payload._id] = payload;
}

export function removeProduct(state, payload) {
  state.cart[payload._id] = payload;
  if (state.cart[payload._id].quantity == 0) {
    delete state.cart[payload._id];
  }
}

export function setSuccessCart(state, payload) {
  state.count = 0;
  state.success = true;
  state.cart = new Array();
  state.successMessage = payload;
  state.error = false;
  state.errorMessage = "";
}

export function setErrorCart(state, payload) {
  state.success = false;
  state.successMessage = "";
  state.error = true;
  state.errorMessage = payload;
}
