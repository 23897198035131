export function setSales(state, payload) {
  state.sales = payload;
  state.sale = null;
  state.error = false;
  state.errorMessage = "";
}

export function setSale(state, payload) {
  state.sales = null;
  state.sale = payload;
  state.error = false;
  state.errorMessage = "";
}

export function setSuccess(state, payload) {
  state.error = false;
  state.errorMessage = "";
  state.successMessage = payload;
  state.success = true;
}

export function setError(state, payload) {
  state.error = true;
  state.errorMessage = payload;
  state.success = false;
  state.successMessage = "";
}
