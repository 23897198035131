export default {
  users: null,
  oneUser: null,
  address: null,
  error: false,
  errorMessage: "",
  successMessage: "",
  success: false,
  userLoading: false,
  usersChart: null,
  userFindedError: false,
  userFindedSuccess: false,
  userFindedMsg: "",
};
