export function setPages(state, pages) {
  state.pages = pages;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setPage(state, page) {
  state.page = page;
  state.error = false;
  state.errorMessage = "";
}

export function setSuccess(state, payload) {
  state.success = true;
  state.successMessage = payload;
  state.error = false;
  state.errorMessage = "";
}

export function setError(state, payload) {
  state.error = true;
  state.errorMessage = payload;
  state.success = false;
  state.successMessage = "";
}
