export function setProducts(state, payload) {
  state.products = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setProduct(state, payload) {
  state.product = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}
export function setPagination(state, payload) {
  state.pagination = payload;
}

export function setError(state, payload) {
  state.products = null;
  state.product = null;
  state.error = true;
  state.errorMessage = payload;
  state.success = false;
  state.successMessage = "";
}

export function setSuccess(state, payload) {
  state.products = null;
  state.product = null;
  state.error = false;
  state.errorMessage = "";
  state.success = true;
  state.successMessage = payload;
}

export function setSearchCode(state, payload) {
  state.searchCodeSuccess = true;
  state.searchCodeError = false;
  state.searchCodeMsg = payload;
}

export function setSearchCodeError(state, payload) {
  state.searchCodeError = true;
  state.searchCodeMsg = payload;
  state.searchCodeSuccess = false;
}
