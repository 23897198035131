export function setUser(state, user) {
  state.user = user;
  state.isLogged = true;
  state.error = false;
  state.errorMessage = "";
  state.errorPassword = false;
  state.passwordErrorMsg = "";
  state.successPassword = false;
  state.passwordMessage = "";
}

export function setInfo(state, payload) {
  state.info = payload;
  state.error = false;
  state.errorMessage = "";
}

export function logout(state, paylaod) {
  state.user = null;
  state.role = null;
  state.isLogged = false;
  state.error = false;
  state.errorMessage = "";
}

export function authError(state, error) {
  state.error = true;
  state.errorMessage = error;
  state.user = null;
  state.isLogged = false;
}

export function userError(state, payload) {
  state.error = true;
  state.errorMessage = payload;
  state.user = null;
}

export function setRole(state, payload) {
  state.role = payload;
  state.errorMessage = "";
  state.error = false;
}

export function setResetPassword(state, payload) {
  state.successPassword = true;
  state.passwordMessage = payload;
  state.errorMessage = "";
  state.error = false;
  state.errorPassword = false;
  state.passwordErrorMsg = "";
}

export function setErrorPassword(state, payload) {
  state.errorPassword = true;
  state.passwordErrorMsg = payload;
  state.successPassword = false;
  state.passwordMessage = "";
}

export function setCodesClear(state, payload) {
  state.successPassword = false;
  state.passwordMessage = "";
  state.errorPassword = false;
  state.passwordErrorMsg = "";
  state.errorMessage = "";
  state.error = false;
}
