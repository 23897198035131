import axios from "axios";

export async function getLocations({ commit }) {
  try {
    const headers = {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    await axios
      .get("/locations", { headers })
      .then((response) => {
        commit("setLocations", response.data);
      })
      .catch((err) => {
        commit("setError", err.response.data.message);
        console.log(err);
      });
  } catch (error) {
    commit("setError", error.response.data.message);
    console.log(error);
  }
}

export async function getLocation({ commit }, data) {
  try {
    const headers = {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    await axios
      .get(`/locations/${data}`, { headers })
      .then((response) => {
        const data = response.data;
        console.log(data);

        commit("setLocation", data.location);
        commit("setCosts", data.costs);
      })
      .catch((err) => {
        commit("setError", err.response.data.message);
        console.log(err);
      });
  } catch (error) {
    commit("setError", error.response.data.message);
    console.log(error);
  }
}
