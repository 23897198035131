import axios from "axios";

export async function getCategories({ commit }, data) {
  try {
    commit("setCategoryLoading", true);

    if (data == undefined) {
      data = "?level=1";
    }

    await axios
      .get(`/categories${data}`)
      .then((response) => {
        commit("setCategories", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.log(e);
  } finally {
    commit("setCategoryLoading", false);
  }
}

export async function getCategoryName({ commit }, data) {
  try {
    await axios
      .get("/categories/", data)
      .then((response) => {
        commit("setCategories", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.log(e);
  }
}

export async function getCategory({ commit }, data) {
  try {
    commit("setCategoryLoading", true);
    await axios
      .get(`/products/${data}`)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.log(e);
  } finally {
    commit("setCategoryLoading", false);
  }
}

export async function postCategory({ commit }, data) {
  try {
    commit("setCategoryLoading", true);

    const headers = {
      token: localStorage.getItem("token"),
    };

    await axios
      .post(`/categories/`, data, { headers })
      .then((response) => {
        commit("setCategory", response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("setCategoryPublishError", err.response.data.message);
      });
  } catch (e) {
    console.log(e);
    commit("categoryError", e.response.data.message);
  } finally {
    commit("setCategoryLoading", false);
  }
}

export function clearCategoryErrors({ commit }) {
  commit("setClearErrors", true);
}

export async function updateCategory({ commit }, data) {
  try {
    commit("setCategoryLoading", true);

    const headers = {
      token: localStorage.getItem("token"),
    };

    await axios
      .put(`/categories/${data._id}`, data, { headers })
      .then((response) => {
        commit("setCategory", response.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        commit("categoryError", err.response.data.message);
      });
  } catch (e) {
    console.log(e);
    commit("categoryError", e);
  } finally {
    commit("setCategoryLoading", false);
  }
}

export async function deleteCategory({ commit }, id) {
  try {
    commit("setCategoryLoading", true);

    const headers = {
      token: localStorage.getItem("token"),
    };

    await axios
      .delete(`/categories/${id}`, { headers })
      .then((response) => {
        console.log(response.data.message);
        commit("setSuccess", response.data.message);
      })
      .catch((err) => {
        console.log(err);
        commit("categoryError", err);
      });
  } catch (e) {
    console.log(e);
    commit("categoryError", e);
  } finally {
    commit("setCategoryLoading", false);
  }
}
