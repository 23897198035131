export function setBanners(state, payload) {
  state.banners = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setBanner(state, payload) {
  state.banner = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setError(state, payload) {
  state.banners = null;
  state.banner = null;
  state.error = true;
  state.errorMessage = payload;
  state.success = false;
  state.successMessage = "";
}

export function setSuccess(state, payload) {
  state.banners = null;
  state.banner = null;
  state.error = false;
  state.errorMessage = "";
  state.success = true;
  state.successMessage = payload;
}
