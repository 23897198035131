import axios from "axios";
import * as cookies from "vue-cookies";
import * as moment from "moment/moment";

export async function getSales({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/sales/${data}`, { headers })
      .then((result) => {
        const { docs, ...data } = result.data;

        docs.forEach((item) => {
          item.createdAt = moment(item.createdAt).format("L");
        });

        commit("setSales", docs);
        commit("setPagination", data, { root: true });
      })
      .catch((err) => {
        commit("setError", err.response.data.message);
        console.log(err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", err.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getSale({ commit }, id) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/sales/${id}`, { headers })
      .then((result) => {
        const data = result.data;
        data.createdAt = moment(data.createdAt).format("L");

        commit("setSale", result.data);
      })
      .catch((err) => {
        commit("setError", err.response.data.message);
        console.log(err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", err.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function postSale({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .post(`/sales`, data, { headers })
      .then((result) => {
        commit("setSale", result.data);
        commit("setSuccess", "Venta creada con éxito");
      })
      .catch((err) => {
        commit("setError", err.response.data.message);
        console.log(err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", err.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}
