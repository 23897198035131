import axios from "axios";
import * as cookies from "vue-cookies";
import * as moment from "moment/moment";

export async function createPage({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .post("/pages", data, { headers })
      .then((result) => {
        commit("setPage", result.data);
      })
      .catch((err) => {
        commit("setError", err.response.data);
      });
  } catch (error) {
    commit("setError", error.response.data);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getPages({ commit }, page) {
  try {
    commit("setLoading", true, { root: true });

    await axios
      .get(`/pages/${page}`)
      .then((result) => {
        const { docs, ...data } = result.data;
        let pages = [];
        docs.forEach(({ createdAt, updatedAt, title, _id, status }) => {
          createdAt = moment(createdAt).fromNow();
          updatedAt = moment(updatedAt).fromNow();

          pages.push({
            updatedAt,
            createdAt,
            title,
            _id,
            status,
          });
        });

        commit("setPages", pages);
        commit("setPagination", data, { root: true });
      })
      .catch((err) => {
        commit("setError", err.response.data);
      });
  } catch (error) {
    commit("setError", error.response.data);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getPage({ commit }, id) {
  try {
    commit("setLoading", true, { root: true });

    await axios
      .get(`/pages/${id}`)
      .then((result) => {
        commit("setPage", result.data);
      })
      .catch((err) => {
        console.log(err);
        commit("setError", err.response.data);
      });
  } catch (error) {
    commit("setError", error.response.data);
  } finally {
    commit("setLoading", false, { root: true });
  }
}
