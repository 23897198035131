import axios from "axios";
import * as cookies from "vue-cookies";
import * as moment from "moment";
moment.locale("es-mx");

export async function getTransfers({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };
    if (!data) {
      data = "/";
    }

    await axios
      .get(`/transfers${data}`, { headers })
      .then((response) => {
        const { docs, ...data } = response.data;
        // updatedAt
        docs.forEach((item) => {
          item.createdAt = moment(item.createdAt).fromNow();
          item.updatedAt = moment(item.updatedAt).fromNow();
        });

        commit("setTransfers", docs);
        commit("setPagination", data, { root: true });
      })
      .catch((err) => {
        console.log(err.response.data.message);
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", err.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getTransfer({ commit }, id) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/transfers/${id}`, { headers })
      .then((response) => {
        const data = response.data;
        data.createdAt = moment(data.createdAt).startOf().fromNow();

        commit("setTransfer", data);
      })
      .catch((err) => {
        console.log(err);
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", err.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function updateTransfer({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .put(`/transfers/${data._id}`, data, { headers })
      .then((response) => {
        const data = response.data;
        console.log(data);

        data.createdAt = moment(item.createdAt).startOf("day").fromNow();

        commit("setTransfer", data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", err.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function createTransfer({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    console.log({ data });

    await axios
      .post(`/transfers`, data, { headers })
      .then((response) => {
        const data = response.data;
        console.log(data);

        data.createdAt = moment(item.createdAt).startOf("day").fromNow();

        commit("setTransfer", data);
        commit("setSuccess", "Almacen creado");
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          commit("setError", err.response);
        }
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function deleteTransfer({ commit }, id) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .delete(`/transfers/${id}`, { headers })
      .then((response) => {
        commit("setSuccess", "eliminado");
      })
      .catch((err) => {
        console.log(err.response.data.message);
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", err.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}
