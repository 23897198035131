export function setLocations(state, payload) {
  state.locations = payload;
  state.location = null;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setLocation(state, payload) {
  state.location = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setCosts(state, payload) {
  state.costs = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setError(state, payload) {
  state.error = true;
  state.errorMessage = payload;
  state.success = false;
  state.successMessage = "";
}
export function setSuccess(state, payload) {
  state.success = true;
  state.successMessage = payload;
  state.error = false;
  state.errorMessage = "";
}
