import axios from "axios";

export async function getCurrency({ commit }, data) {
  try {
    const response = await axios
      .get("/change")
      .then((result) => {
        commit("setCurrency", result.data[0].normal);
        commit("set_ID", result.data[0]._id);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
}

export function changeType({ commit }, data) {
  commit("setType", data);
}
