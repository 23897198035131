export function setCategories(state, categories) {
  state.categories = categories;
  state.error = false;
  state.errorMessage = "";
}

export function setCategory(state, category) {
  state.category = category;
  state.error = false;
  state.errorMessage = "";
}

export function categoryError(state, error) {
  state.error = true;
  state.errorMessage = error;
}

export function setSuccess(state, payload) {
  state.successMessage = payload;
  state.success = true;
  state.error = false;
  state.errorMessage = "";
}

export function setCategoryLoading(state, payload) {
  state.categoryLoading = payload;
}

export function setCategoryPublishError(state, payload) {
  state.publishCategoryError = true;
  state.publishCategoryMsg = payload;
  state.error = false;
  state.errorMessage = "";
  state.successMessage = "";
  state.success = false;
}

export function setClearErrors(state, payload) {
  state.publishCategoryError = false;
  state.publishCategoryMsg = "";
  state.error = false;
  state.errorMessage = "";
  state.successMessage = "";
  state.success = false;
}
