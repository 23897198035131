import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.yellow.accent4,
        secondary: colors.yellow.accent3,
        blue: "#23b9d7",
      },
    },
  },
});
