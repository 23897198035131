export default {
  products: null,
  product: null,
  pagination: {},
  error: false,
  errorMessage: "",
  success: false,
  successMessage: "",
  searchCodeError: false,
  searchCodeSuccess: false,
  searchCodeMsg: "",
};
