import axios from "axios";
import * as cookies from "vue-cookies";
import swal from "sweetalert2";
import * as moment from "moment";
moment.locale("es-mx");

export async function getProducts({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    if (data == undefined) {
      data = "/";
    }

    await axios
      .get(`/products${data}`)
      .then((response) => {
        const {
          hasNextPage,
          hasPrevPage,
          limit,
          nextPage,
          page,
          pagingCounter,
          totalDocs,
          totalPages,
          docs,
        } = response.data;
        let docsProducts = docs;

        docsProducts.forEach((product) => {
          product.total = 0;
          product.updatedAt = moment(product.updatedAt).fromNow();
        });

        commit(
          "setPagination",
          {
            hasNextPage,
            hasPrevPage,
            limit,
            nextPage,
            page,
            pagingCounter,
            totalDocs,
            totalPages,
          },
          { root: true }
        );
        commit("setProducts", docsProducts);
      })
      .catch((err) => {
        console.log(err);
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", error);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getProduct({ commit }, id) {
  try {
    commit("setLoading", true, { root: true });

    await axios
      .get(`/products/${id}`)
      .then((response) => {
        commit("setProduct", response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", error);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function updateProduct({ commit }, product) {
  try {
    commit("setLoading", true, { root: true });
    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    const { _id, ...data } = product;

    await axios
      .put(`/products/${_id}`, data, { headers })
      .then((response) => {
        swal.fire({
          icon: "success",
          text: "El producto se a actualizado",
        });

        commit("setSuccess", "producto actualizado");
      })
      .catch((err) => {
        console.log(err);
        commit("productError", err);
      });
  } catch (e) {
    console.log(e);
    commit("setError", e);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function deleteProduct({ commit }, id) {
  try {
    commit("setLoading", true, { root: true });
    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .delete(`/products/${id}`, { headers })
      .then((response) => {
        commit("setSuccess", response.data.message);
      })
      .catch((err) => {
        commit("productError", err.message);
      });
  } catch (e) {
    console.log(e);
    commit("productError", e.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function searchCode({ commit }, data) {
  try {
    commit("setProductLoading", true);

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/products/find/${data}`, { headers })
      .then((response) => {
        commit("setSearchCode", response.data.message);
      })
      .catch((err) => {
        commit("setSearchCodeError", err.response.data.message);
      });
  } catch (e) {
    console.log(e);
    commit("productError", err.message);
  } finally {
    commit("setProductLoading", false);
  }
}
