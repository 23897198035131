export function setBills(state, payload) {
  state.bills = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setPayments(state, payload) {
  state.payments = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setClients(state, payload) {
  state.clients = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setUses(state, payload) {
  state.uses = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setBill(state, payload) {
  state.bill = payload;
  state.error = false;
  state.errorMessage = "";
  state.success = false;
  state.successMessage = "";
}

export function setError(state, payload) {
  state.banners = null;
  state.banner = null;
  state.error = true;
  state.errorMessage = payload;
  state.success = false;
  state.successMessage = "";
}

export function setSuccess(state, payload) {
  state.banners = null;
  state.banner = null;
  state.error = false;
  state.errorMessage = "";
  state.success = true;
  state.successMessage = payload;
}
