export default {
  orders: null,
  order: null,
  error: false,
  errorMessage: "",
  successMessage: "",
  success: false,
  orderLoading: false,
  pagination: {},
};
