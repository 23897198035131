import axios from "axios";
import * as moment from "moment";
moment.locale("es-mx");

export async function createCostumer({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    await axios
      .post("/customers", data, { headers })
      .then((response) => {
        commit("setClientSuccess", "Creado con éxito");
      })
      .catch((err) => {
        console.log(err);
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function updateCostumer({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    await axios
      .put(`/customers/${data._id}`, data, { headers })
      .then((response) => {
        commit("setClientSuccess", "Actualizado con éxito");
      })
      .catch((err) => {
        console.log(err);
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function deleteCostumer({ commit }, _id) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    await axios
      .delete(`/customers/${_id}`, { headers })
      .then((response) => {
        commit("setClientSuccess", "Eliminado con éxito");
      })
      .catch((err) => {
        console.log(err);
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getCostumers({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    await axios
      .get(`/customers/${data}`, { headers })
      .then((response) => {
        const {
          hasNextPage,
          hasPrevPage,
          limit,
          nextPage,
          page,
          pagingCounter,
          totalDocs,
          totalPages,
          docs,
        } = response.data;
        // docs.forEach((element) => {
        //   element.createdAt = moment(element.createdAt).format("DD/MM/YYYY");
        // });
        commit("setCostumers", docs);
        commit("setPagination", {
          hasNextPage,
          hasPrevPage,
          limit,
          nextPage,
          page,
          pagingCounter,
          totalDocs,
          totalPages,
        });
      })
      .catch((err) => {
        console.log(err.response);
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    console.log(error);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getCostumer({ commit }, _id) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    await axios
      .get(`/customers/${_id}`, { headers })
      .then((response) => {
        commit("setCostumer", response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("setError", err.response.data.message);
      });
  } catch (error) {
    console.log(error);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export function clearCodes({ commit }) {
  commit("setClearCodes", true);
}
