import axios from "axios";
import * as cookies from "vue-cookies";

export async function createMenu({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .post("/menu", data, { headers })
      .then((result) => {
        commit("setMenu", result.data);
      })
      .catch((err) => {
        commit("setError", err.response.data);
      });
  } catch (error) {
    commit("setError", error.response.data);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getMenu({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    await axios
      .get("/menu")
      .then((result) => {
        commit("setMenu", result.data);
      })
      .catch((err) => {
        commit("setError", err.response.data);
      });
  } catch (error) {
    commit("setError", error.response.data);
  } finally {
    commit("setLoading", false, { root: true });
  }
}
