import axios from "axios";
import * as cookies from "vue-cookies";
import * as moment from "moment";
moment.locale("es-mx");

export async function getBanners({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });
    if (!data) {
      data = `/?page=1&limit=30`;
    }
    await axios
      .get(`/banners${data}`)
      .then((response) => {
        const { docs, ...pagination } = response.data;

        commit("setBanners", docs);
        commit("setPagination", pagination, { root: true });
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          commit("setError", err.response.data.message);
        }
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function createBanner({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .post(`/banners`, data, { headers })
      .then((response) => {
        commit("setBanner", response.data);
        commit("setSuccess", "se a creado con éxito el banner");
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          commit("setError", err.response.data.message);
        }
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function updateBanner({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    const { _id, ...dto } = data;

    await axios
      .put(`/banners/${_id}`, dto, { headers })
      .then((response) => {
        commit("setBanner", response.data);
        commit("setSuccess", "se a creado con éxito el banner");
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          commit("setError", err.response.data.message);
        }
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}
