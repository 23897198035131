import axios from "axios";
import * as cookies from "vue-cookies";
import swal from "sweetalert2";
import * as moment from "moment/moment";
moment.locale("es-mx");

export async function getUsers({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });
    if (data == undefined) {
      data = "/";
    }

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/users${data}`, { headers })
      .then((response) => {
        const { docs, ...data } = response.data;
        docs.forEach((element) => {
          element.createdAt = moment(element.createdAt).format("DD/MM/YYYY");
        });

        commit("setUsers", docs);
        commit("setPagination", data, { root: true });
      })
      .catch((err) => {
        console.log(err);
        commit("UserError", err.response.data.message);
      });
  } catch (e) {
    console.log(e);
    commit("UserError", e.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getUser({ commit }, id) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/users/${id}`, { headers })
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("UserError", err.response.data.message);
      });
  } catch (e) {
    console.log(e);
    commit("UserError", e.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getMyInfo({ commit }) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/profile`, { headers })
      .then((response) => {
        commit("setAddress", response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("UserError", err.response.data.message);
      });
  } catch (e) {
    console.log(e);
    commit("UserError", e.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function updateMyInfo({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .put(`/profile/info`, data, { headers })
      .then((response) => {
        const { name, last_name, phone_number, address } = response.data;

        swal.fire({
          icon: "success",
          text: "Datos de envío actualizados",
        });

        commit("setAddress", { name, last_name, phone_number, ...address });
      })
      .catch((err) => {
        console.log(err);
        // commit("UserError", err.response.data.message);
        swal.fire({
          icon: "error",
          text: err.response.data.message,
        });
      });
  } catch (e) {
    console.log(e);
    swal.fire({
      icon: "error",
      text: err.response.data.message,
    });
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getEmailUser({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/users/${data}`, { headers })
      .then((response) => {
        if (response.data.message) {
          commit("setUserFinded", response.data.message);
        } else {
          commit("setClearErrorCodes", true);
        }
      })
      .catch((err) => {
        console.log({ err });
        commit("setUserFindedError", err.response);
      });
  } catch (e) {
    commit("UserError", e.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export function clearCodes({ commit }) {
  commit("setClearErrorCodes", true);
}

export async function createUser({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .post("/users/", data, { headers })
      .then((response) => {
        commit("setOneUser", response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("UserError", err.response.data.message);
      });
  } catch (e) {
    console.log(e);
    commit("UserError", e.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function updateUser({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .put(`/users/${data._id}`, data, { headers })
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("UserError", err.response.data.message);
      });
  } catch (e) {
    console.log(e);
    commit("UserError", e.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function deleteUser({ commit }, id) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .delete(`/users/${id}`, { headers })
      .then((response) => {
        console.log(response.data.message);
        commit("setSuccess", response.data.message);
      })
      .catch((err) => {
        console.log(err);
        commit("UserError", err.response.data.message);
      });
  } catch (e) {
    console.log(e);
    commit("UserError", e.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getStatsUsers({ commit }, data) {
  try {
    commit("setLoading", true, { root: true });

    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/users`, { headers })
      .then((response) => {
        commit("setUsersChart", response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("UserError", err.response.data.message);
      });
  } catch (e) {
    console.log(e);
    commit("UserError", e.response.data.message);
  } finally {
    commit("setLoading", false, { root: true });
  }
}
