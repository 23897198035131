import axios from "axios";
import * as cookies from "vue-cookies";
import * as moment from "moment";
moment.locale("es-mx");

export async function getBills({ commit }) {
  try {
    commit("setLoading", true, { root: true });
    await axios
      .get(`/bills`)
      .then((response) => {
        commit("setBills", response.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          commit("setError", err.response.data.message);
        }
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response);
  } finally {
    commit("setLoading", false, { root: true });
  }
}

export async function getClients({ commit }) {
  try {
    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/clients`, { headers })
      .then((response) => {
        commit("setClients", response.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          commit("setError", err.response.data.message);
        }
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response);
  }
}

export async function getUsesCfdi({ commit }) {
  try {
    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/bills/usoCfdi`, { headers })
      .then((response) => {
        commit("setUses", response.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          commit("setError", err.response.data.message);
        }
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response);
  }
}

export async function getWayToPay({ commit }) {
  try {
    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .get(`/bills/formapago`, { headers })
      .then((response) => {
        commit("setPayments", response.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          commit("setError", err.response.data.message);
        }
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response);
  }
}

export async function createBill({ commit }, data) {
  try {
    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .post(`/bills/order`, { headers })
      .then((response) => {
        // commit("setPayments", );
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          commit("setError", err.response.data.message);
        }
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response);
  }
}

export async function createBillAutomatic({ commit }, data) {
  try {
    const headers = {
      authorization: `Bearer ${cookies.get("token")}`,
    };

    await axios
      .post(`/bills`, data, { headers })
      .then((response) => {
        console.log(response.data);
        // commit("setPayments", response.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          commit("setError", err.response.data.message);
        }
      });
  } catch (error) {
    console.log(error);
    commit("setError", error.response);
  }
}
